<template>
  <div class="auth-wrapper auth-v2 px-2 justify-content-center align-items-center">
    <b-card class="w-50">
      <b-card-header class="text-align-center justify-content-center">
        <b-link >
          <b-img
              class="logo-login justify-content-center align-items-center"
              :src="require('@/assets/images/logo/intellyscale.svg')"

          />
        </b-link>
      </b-card-header>
      <b-card-body>
        <b-alert
            variant="success"
            :show="hideForm"
        >
          <div class="alert-body">
            <span><strong class="h4 text-success mb-1 d-block">{{ $t('Registration successfull!') }}</strong>
              <p class="font-weight-normal">
                {{ $t('A fresh verification link has been sent to your email address.') }}<br>
                {{ $t('Before proceeding, please check your email for a verification link. If you did not receive the email') }},
              </p>

              <b-button
                  variant="success"
                  type="submit"
                  size="sm"
                  class="mt-2"
                  @click="resendVerificationEmail"
              >
                  {{ $t('click here to request another') }}
                </b-button>
            </span>
          </div>
        </b-alert>
        <div class="" v-if="!hideForm">
          <b-card-text class="mb-1 d-flex justify-content-center align-items-center">
            {{ $t('Register to start the adventure now!') }} 🚀
          </b-card-text>

        </div>
        <validation-observer ref="registerForm" #default="{ invalid }">
          <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
              v-if="!hideForm"
          >
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('Firstname')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Firstname')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="first_name"
                        :placeholder="$t('Firstname')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('first_name')" class="text-danger">{{ $t(validation.first_name[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Last name')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Last name')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="last_name"
                        :placeholder="$t('Last name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('last_name')" class="text-danger">{{ $t(validation.lastname[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Company')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Company')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="company"
                        :placeholder="$t('Company')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('company')" class="text-danger">{{ $t(validation.company[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Street')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Street')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="street"
                        :placeholder="$t('Street')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('street')" class="text-danger">{{ $t(validation.street[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Postal')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Postal')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="postal"
                        :placeholder="$t('Postal')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('postal')" class="text-danger">{{ $t(validation.postal[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('City')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('City')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="city"
                        :placeholder="$t('City')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('city')" class="text-danger">{{ $t(validation.city[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Country')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Country')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="country"
                        :placeholder="$t('Country')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('country')" class="text-danger">{{ $t(validation.country[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Email')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Email')"
                      rules="required|email"
                  >
                    <b-form-input
                        v-model="email"
                        :placeholder="$t('email@domain.de')"
                    />
                    <small v-if="Object.keys(validation).includes('email')" class="text-danger">{{ $t(validation.email[0]) }}</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Password')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Password')"
                      vid="Password"
                      rules="required|password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          v-model="password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="register-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="Object.keys(validation).includes('password')" class="text-danger">{{ $t(validation.password[0]) }}</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Confirm Password')">
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Confirm Password')"
                      rules="required|confirmed:Password"
                  >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                          v-model="password_confirmation"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordconfirmFieldType"
                          :placeholder="$t('Confirm Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordconfirmToggleIcon"
                            @click="togglePasswordConfirmVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="Object.keys(validation).includes('password_confirmation')" class="text-danger">{{ $t(validation.password_confirmation[0]) }}</small>
                    <small class="text-danger">{{ (errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-1">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('Privacy policy')"
                      rules="required"
                  >
                    <b-form-checkbox
                        id="register-privacy-policy"
                        v-model="privacy_policy"
                        name="checkbox-1"
                    >
                      {{ $t('I agree to') }}
                      <b-link>{{ $t('privacy policy & terms') }}</b-link>
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr/>
                <b-button
                    variant="success"
                    block
                    type="submit"
                >
                  {{ $t('Sign up') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <p class="text-center mt-2" v-if="!hideForm">
          <span>{{ $t('Already have an account?') }}</span>
          <b-link :to="{name:'auth-login'}">
            <span>&nbsp;{{ $t('Sign in instead') }}</span>
          </b-link>
        </p>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCardBody,
  BCardHeader,
  BCard,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAlert
} from 'bootstrap-vue'
import {email, required} from '@validations'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import {getHomeRouteForLoggedInUser} from '@/auth/utils'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      first_name: '',
      last_name: '',
      company: '',
      street: '',
      postal: '',
      country: '',
      city: '',
      email: '',
      password: '',
      password_confirmation: "",
      privacy_policy: false,
      sideImg: require('@/assets/images/portrait/pages/register-v2.svg'),
      validation: {},
      validate: false,
      hideForm: true,
      registerFormEnabled: process.env.VUE_APP_ENABLE_REGISTER,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/portrait/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate()
          .then((success) => {
            this.validation = {}

            if (success) {
              this.$http.post('/clients/register', {
                first_name: this.first_name,
                last_name: this.last_name,
                company: this.company,
                street:this.street,
                postal:this.postal,
                country:this.country,
                city:this.city,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
              }).then(res => {
                this.hideForm = true
              }).catch(error => {
                if (error.response.status == 422) {
                  this.validation = error.response.data.errors;
                  this.validate = true
                } else {
                  this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                    title: this.$i18n.t(error.response.data.message),
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                  });
                }

              })
            }
          })
    },
    resendVerificationEmail() {
      this.$http.post('/clients/verification/resend', {
        email: this.email,
      }).then(res => {
        console.debug(res.status)
        if (res.status == "204") {
          this.$bvToast.toast(this.$t('Your email is still verified.'), {
            title: this.$i18n.t("Error"),
            variant: "danger",
            toaster: "b-toaster-top-right",
          });
        } else {
          this.$bvToast.toast(this.$t('Verification email was successfully sent again.'), {
            title: this.$i18n.t("Success"),
            variant: "success",
            toaster: "b-toaster-top-right",
          });
        }
      }).catch(error => {
      })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.logo-login {
    width: 160px;
}
</style>
